var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("br"),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-12 col-lg-12" },
        [
          _c("c-upload", {
            attrs: {
              attachInfo: _vm.attachInfo,
              editable: _vm.editable && !_vm.disabled,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }